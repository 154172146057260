import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"


const SongList = ({ data }) => {
  var [filter, setFilter] = useState("");

  return (
    <>
    <label className="block leading-normal pb-3">
      Filter: <input 
      type="text" 
      value={filter}
      onChange={(v)=>setFilter(v.currentTarget.value)}
      className="border py-1 px-3"
      ></input> 
    </label>
    <div className="flex">
      {data.allMarkdownRemark.group.map((group) => {
        return (
          <div key={group.fieldValue}>
            <h2>{group.fieldValue}</h2>
            {group.edges
            .filter(({ node }) => {
              const { title, artist } = node.frontmatter
              return new RegExp(filter, "i").test(title + " " + artist)
            })
            .map(({ node }) => {
              const { path } = node.fields
              const { title, artist } = node.frontmatter
              return (
                <Link key={path} to={path}
                  className="block group text-sm hover:text-purple-400">
                  {title && artist && 
                    <span>
                      {title}&nbsp;
                      <span className="text-xs text-gray-600 uppercase group-hover:text-purple-400">{artist} </span>
                    </span>
                  }
                  {(!title || !artist) && 
                    <span>{path} </span>
                  }
                </Link>
              )
            })}
          </div>
        )
      })}
    </div>
  </>
  )
} 

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark (
          sort: { order: DESC, fields: [fields___genre, frontmatter___title] } 
        ){
   			 group(field:fields___genre){
     			fieldValue
    
          edges {
            node {
              fields{ path genre }
              frontmatter {
                title
                artist
              }
            }
          }
        }
      }
    }
    `}
    render={data => <SongList data={data} {...props} />}
  />
)
